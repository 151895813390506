<template>
  <div class="page inPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Calendar</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法" inset>
        <lee-calendar v-model="show1" round @onConfirm="onConfirm1"/>
        <lee-calendar v-model="show2" type="multiple" round @onConfirm="onConfirm2"/>
        <lee-calendar v-model="show3" type="range" round @onConfirm="onConfirm3"/>
        <lee-cell title="选择单个日期" is-link @click="show1 = true" :footer="date1"></lee-cell>
        <lee-cell title="选择多个日期" is-link @click="show2 = true" :footer="date2"></lee-cell>
        <lee-cell title="选择日期区间" is-link @click="show3 = true" :footer="date3"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="自定义日历" inset>
        <lee-calendar v-model="show4" round @onConfirm="onConfirm4"/>
        <lee-calendar v-model="show5" round title="航班选择" @onConfirm="onConfirm5"/>
        <lee-calendar v-model="show6" round color="#f00" text-color="#fff" @onConfirm="onConfirm6"/>
        <lee-calendar v-model="show7" type="range" round confirm-text="完成" confirm-disabled-text="请选择结束时间"
                      @onConfirm="onConfirm7"/>
        <lee-calendar v-model="show8" round @onConfirm="onConfirm8" min-date="2008-10-10" max-date="2009-10-1"/>
        <lee-calendar v-model="show9" :max-range="3" type="range" round
                      @onConfirm="onConfirm9"/>
        <lee-calendar :dayInfo="dayInfo" v-model="show10" type="range" round @onConfirm="onConfirm10"/>

        <lee-cell title="圆角日历" is-link @click="show4 = true" :footer="date4"></lee-cell>
        <lee-cell title="自定义日历标题" is-link @click="show5 = true" :footer="date5"></lee-cell>
        <lee-cell title="自定义颜色" is-link @click="show6 = true" :footer="date6"></lee-cell>
        <lee-cell title="自定义按钮文字" is-link @click="show7 = true" :footer="date7"></lee-cell>
        <lee-cell title="自定义日期范围" is-link @click="show8 = true" :footer="date8"></lee-cell>
        <lee-cell title="日期期间最大范围" is-link @click="show9 = true" :footer="date9"></lee-cell>
        <lee-cell title="自定义日期文案" is-link @click="show10 = true" :footer="date10"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="日历方法" inset>
        <lee-calendar v-model="show11" type="range"  round @onConfirm="onConfirm11" ref="calendar"/>
        <lee-cell title="滚动到某个日期(2023-10-4)" is-link @click="show11 = true" :footer="date11"></lee-cell>
      </lee-cell-group>
      <lee-cell-group title="事件监听" inset>
        <lee-calendar v-model="show12" round @onConfirm="onConfirm12"  @onOpen="onOpen"  @onClose="onClose"/>
        <lee-cell title="事件监听" is-link @click="show12 = true" :footer="date12"></lee-cell>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {showToast} from "@/leeplus";
import {ref, watch} from 'vue'
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}

const show1 = ref(false)
const show2 = ref(false)
const show3 = ref(false)
const show4 = ref(false)
const show5 = ref(false)
const show6 = ref(false)
const show7 = ref(false)
const show8 = ref(false)
const show9 = ref(false)
const show10 = ref(false)
const show11 = ref(false)
const show12 = ref(false)
const date1 = ref('')
const date2 = ref('')
const date3 = ref('')
const date4 = ref('')
const date5 = ref('')
const date6 = ref('')
const date7 = ref('')
const date8 = ref('')
const date9 = ref('')
const date10 = ref('')
const date11 = ref('')
const date12 = ref('')
const formatDate = (date) => `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
const onConfirm1 = (date) => {
    date1.value = formatDate(date);
}
const onConfirm2 = (date) => {
    date2.value = `选择了 ${date.length} 个日期`;
}
const onConfirm3 = (date) => {
    const [start, end] = date;
    date3.value = `${formatDate(start)} - ${formatDate(end)}`;
}
const onConfirm4 = (date) => {
    date4.value = formatDate(date);
}
const onConfirm5 = (date) => {
    date5.value = formatDate(date);
}
const onConfirm6 = (date) => {
    date6.value = formatDate(date);
}
const onConfirm7 = (date) => {
    const [start, end] = date;
    date7.value = `${formatDate(start)} - ${formatDate(end)}`;
}
const onConfirm8 = (date) => {
    date8.value = formatDate(date);
}
const onConfirm9 = (date) => {
    const [start, end] = date;
    date9.value = `${formatDate(start)} - ${formatDate(end)}`;
}
const onConfirm10 = (date) => {
    const [start, end] = date;
    date10.value = `${formatDate(start)} - ${formatDate(end)}`;
}
const onConfirm11 = (date) => {
    const [start, end] = date;
    date11.value = `${formatDate(start)} - ${formatDate(end)}`;
}
const onConfirm12 = (date) => {
    date12.value = formatDate(date);
}
const onOpen = () => {
    showToast('打开')
}
const onClose = () => {
    showToast('关闭')
}




const dayInfo = {
    type: {
        start: '入住',
        end: '离店',
    },
    days: [
        {
            month: 5,
            day: 1,
            text: '劳动节'
        },
        {
            month: 5,
            day: 4,
            text: '青年节'
        },
        {
            month: 10,
            day: 1,
            text: '国庆节'
        },
    ]
}
const calendar = ref(null)
watch(show11,(value)=>{
    if(value){
        calendar.value.scrollToDate('2023-10-4')
    }
},{
    immediate:false
})


</script>
<style>


</style>
